import React from "react";
import "./CharityProfile.scss";
import $ from "jquery";
import MyContext from "../MyContext";
import { InputTheme4 } from "../../res/styler/InputThemes/InputThemes";
import { MdHomeRepairService } from "react-icons/md";
import { ButtonTheme1 } from "../../res/styler/ButtonThemes/ButtonThemes";
import { FaMapMarkerAlt, FaPhoneAlt, FaGlobe, FaBusinessTime } from "react-icons/fa";
import { SiGmail, SiWhatsapp } from "react-icons/si";
import { BsChevronDoubleDown, BsChevronDoubleUp, BsStarFill, BsStarHalf } from 'react-icons/bs';
import { ImArrowRight2 } from 'react-icons/im';
import { MdOutlineArrowForwardIos, MdOutlineArrowBackIos, MdOutlineKeyboardBackspace, MdOutlineShare, MdOutlinePrint } from 'react-icons/md';
import { FaRegComment, FaUserCircle } from 'react-icons/fa';
import { AiOutlineHeart, AiFillHeart } from "react-icons/ai";
import { GoReport } from 'react-icons/go';
import { ButtonTheme2 } from "../../res/styler/ButtonThemes/ButtonThemes";
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import AwesomeSlider from 'react-awesome-slider';
import AwsSliderStyles from 'react-awesome-slider/dist/styles.css';
import withAutoplay from 'react-awesome-slider/dist/autoplay';
import 'react-awesome-slider/dist/styles.css';
import 'react-awesome-slider/dist/custom-animations/open-animation.css';
import 'react-awesome-slider/dist/custom-animations/cube-animation.css';
import memories from "../../res/images/memorials/default_memories.jpg";
import charity_image from "../../res/images/charity/charity_new.jpg";
import ReCAPTCHA from "react-google-recaptcha";
import PhoneInput from "react-phone-number-input";
import { faHandHoldingHeart, faHandHoldingWater, faHandshake } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { VscChromeClose } from "react-icons/vsc";
import MaterialIcon from 'material-icons-react';
import x_logo from "../../res/images/X_logo_black.png";
import x_white_logo from "../../res/images/X_icon.png";

class CharityProfile extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      charity_details: {
        name: "",
        google_location: "",
        gallery_images: [],
        services: [],
        comments: []
      },
      name: "",
      email: "",
      phone_no: "",
      message: "",
      manage_charity: "",
      zoomed_item: {},
      image_index: 0,
      comment: "",
      captcha_token: "",
      phone_number_code: "IN",
    }

    this.form_change = this.form_change.bind(this);
    this.enquiry_form_submit = this.enquiry_form_submit.bind(this);
    this.phone_no_change = this.phone_no_change.bind(this);
    this.phone_number_code_change = this.phone_number_code_change.bind(this);
    this.approve_charity = this.approve_charity.bind(this);
    this.delete_charity = this.delete_charity.bind(this);
    this.zoomImage = this.zoomImage.bind(this);
    this.minimizeImage = this.minimizeImage.bind(this);
    this.nextImage = this.nextImage.bind(this);
    this.previousImage = this.previousImage.bind(this);
    this.show_comment_form = this.show_comment_form.bind(this);
    this.comment_form_submit = this.comment_form_submit.bind(this);
    this.charity_profile_page_ref = React.createRef();
    this.captchaRef = React.createRef();
    this.enquiry_form_ref = React.createRef();
    this.name_ref = React.createRef();
    this.phone_no_ref = React.createRef();
    this.email_ref = React.createRef();
    this.message_ref = React.createRef();
    this.month_names = ["January", "February", "March", "April", "May", "June",
      "July", "August", "September", "October", "November", "December"
    ];
  }

  componentDidMount() {
    $(".header_container").addClass("transparent_header");
    if (window.location.pathname == "/Preview/Charity/" + this.props.match.params.charity_id) {
      var obj_this = this;
      $.ajax({
        url: process.env.REACT_APP_API + "/api/viewCharityDetails",
        data: this.context.bind_session({
          charity_id: this.props.match.params.charity_id
        }),
        type: "POST"
      }).done(function (return_data) {
        console.log(return_data);
        obj_this.setState({
          charity_details: return_data.charity_details,
          manage_charity: 1
        }, () => {
          obj_this.charity_profile_page_ref.current.style.visibility = "visible";
          $(".header_container").css("pointer-events", "none");
          $(".like_button,.single_charity, .report_button, .button_theme2_container,.all_charity_button, .new_comment_button, .donate_now_button, .custom_button, .shop_now_button, .save_message_button").css("pointer-events", "none");
          $(".share_print_comment_buttons_container, .footer_container").css("display", "none");
          window.scrollTo({
            top: obj_this.charity_profile_page_ref.current.offsetTop - 80,
            behavior: "smooth"
          });
        });
        obj_this.context.loading(0);
      }).fail(function () {
        $.alert("Network error");
      });
    } else {
      var obj_this = this;
      $.ajax({
        url: process.env.REACT_APP_API + "/api/getCharityDetails",
        data: {
          charity_id: this.props.match.params.charity_id
        },
        type: "POST"
      }).done(function (return_data) {
        console.log(return_data);
        if (return_data.charity_details.status == "DELETED") {
          obj_this.props.history.push('/Charities')
        }
        obj_this.setState({
          charity_details: return_data.charity_details,
        }, () => {
          $(".map_block").html(obj_this.state.charity_details.google_location);
          obj_this.charity_profile_page_ref.current.style.visibility = "visible";
          window.scrollTo({
            top: obj_this.charity_profile_page_ref.current.offsetTop - 80,
            behavior: "smooth"
          });
          obj_this.props.history.replace("/Charity/" + obj_this.state.charity_details.id + "/" + obj_this.state.charity_details.name.replace(/\s+/g, '-') + "/" + obj_this.state.charity_details.serial_no);
        });
        obj_this.context.loading(0);
      }).fail(function () {
        $.alert("Network error");
      });


    }
  }

  componentWillUnmount() {
    $(".footer_container").css("display", "block");
    $(".header_container").css("pointer-events", "auto").removeClass("transparent_header");

  }


  form_change(e) {
    if (e.target.value.length > 200) {
      $.alert("Maximum characters exceeded.")
      return;
    }
    this.setState({
      [e.target.name]: e.target.value
    });
  }

  phone_no_change(value) {
    this.setState({
      phone_no: value,
    });
  }

  phone_number_code_change(country) {
    const country_code = country ?? this.state.phone_number_code;
    this.setState({
      phone_number_code: country_code,
    });
  }

  enquiry_form_submit(e) {
    e.preventDefault();
    if (!this.state.name) {
      $.alert("Name Required");
      window.scrollTo({
        top: $(this.name_ref.current).offset().top - 100,
        behavior: "smooth"
      });
      return;
    }
    if (!this.state.phone_no) {
      $.alert("Phone Number Required");
      window.scrollTo({
        top: $(this.phone_no_ref.current).offset().top - 100,
        behavior: "smooth"
      });
      return;
    }
    var re = /\S+@\S+\.\S+/;
    if (!re.test(this.state.email)) {
      $.alert("Please enter an email address");
      window.scrollTo({
        top: $(this.email_ref.current).offset().top - 100,
        behavior: "smooth"
      });
      return;
    }
    if (!this.state.message) {
      $.alert("Message Required");
      window.scrollTo({
        top: $(this.message_ref.current).offset().top - 100,
        behavior: "smooth"
      });
      return;
    }
    const token = this.captchaRef.current.getValue();
    this.captchaRef.current.reset();
    console.log(this.state);
    var obj_this = this;
    var enquiry_data = {
      name: this.state.name,
      phone_no: this.state.phone_no,
      email: this.state.email,
      message: this.state.message,
      charity_id: this.state.charity_details.id
    }
    enquiry_data.captcha_token = token;
    if (!enquiry_data.captcha_token) {
      $.alert("Please verify you are a human!!");
      return;
    }
    this.context.loading(1);
    console.log(enquiry_data);
    enquiry_data = JSON.stringify(enquiry_data);
    $.ajax({
      url: process.env.REACT_APP_API + "/api/saveCharityEnquiry",
      data: {
        enquiry_data: enquiry_data,
      },
      type: "POST"
    }).done(function (return_data) {
      console.log(return_data);
      obj_this.context.loading(0);
      if (return_data.status == "success") {
        $.alert("Thank you. Your enquiry has been submitted successfully");
        obj_this.setState({
          name: "",
          phone_no: "",
          phone_number_code: "IN",
          email: "",
          message: "",
          enquiry_for: "",
          captcha_token: "",
        });
      } else {
        $.alert("Something Went Wrong. Please Try Again.");
      }
    }).fail(function (return_data) {
      $.alert("Network error!!");
    });
  }

  zoomImage(item, idx) {
    console.log(item);
    this.setState({
      zoomed_item: item,
      image_index: idx
    });
  }

  minimizeImage(item) {
    this.setState({
      zoomed_item: {}
    });
  }

  previousImage() {
    const item = this.state.charity_details.gallery_images[--this.state.image_index];
    if (item)
      this.setState({
        zoomed_item: item,
      });
  }

  nextImage(e) {
    const item = this.state.charity_details.gallery_images[++this.state.image_index];
    if (item)
      this.setState({
        zoomed_item: item,
      });
  }

  show_comment_form() {
    if (this.context.session) {
      $(".add_message_form_ghost").css("display", "block");
    } else {
      this.context.confirm("Sign in to leave a comment", () => {
        this.props.history.push({ pathname: "/Login", url: "/Charity/" + this.props.match.params.charity_id })
      });
      return;
    }
  }

  comment_form_submit(e) {
    e.preventDefault();
    console.log(this.state.comment);
    var obj_this = this;
    this.context.loading(1);
    $(".add_message_form_ghost").css("display", "none");
    $.ajax({
      url: process.env.REACT_APP_API + "/api/saveCharityComments",
      data: this.context.bind_session({
        charity_id: this.props.match.params.charity_id,
        comment: this.state.comment
      }),
      type: "POST"
    }).done(function (return_data) {
      console.log(return_data);
      if (return_data.status == "success") {
        const charity_details = obj_this.state.charity_details;
        charity_details.comments = return_data.comments;
        obj_this.setState({
          charity_details: charity_details,
          comment: ""
        });
        if (return_data.comment_status == "PUBLISHED") {
          $.alert("Your message is published");
        } else {
          $.alert("Your message is waiting for administrator approval, Please check back later.");
        }
      } else {
        $.alert("Something Went Wrong. Please Try Again.")
      }
      obj_this.context.loading(0);
    }).fail(function () {
      $.alert("Network error")
    });
  }

  report_comment(id) {
    if (!this.context.session) {
      this.context.confirm("Sign in to continue", () => {
        this.props.history.push({ pathname: "/Login", url: "/Charity/" + this.props.match.params.charity_id })
      });
      return;
    } else {
      this.context.confirm("Report this comment", () => {
        var obj_this = this;
        this.context.loading(1);
        const report_data = {
          charity_id: this.state.charity_details.id,
          content_id: id,
          content_type: "comment"
        }
        $.ajax({
          url: process.env.REACT_APP_API + "/api/reportCharityContent",
          data: this.context.bind_session({ report_data: JSON.stringify(report_data) }),
          type: "POST"
        }).done(function (return_data) {
          if (return_data.status == "success") {
            obj_this.context.loading(0);
            $.alert("Your request will be reviewed.");
          } else {
            alert("Network error!!");
          }
        }).fail(function (return_data) {
          alert("Network error!!");
        });
      });
    }
  }

  approve_charity() {
    if (!window.confirm("Approve this charity")) {
      return;
    }
    this.context.loading(1);
    var obj_this = this;
    $.ajax({
      url: process.env.REACT_APP_API + "/api/approveCharity",
      data: this.context.bind_session({
        charity_id: this.props.match.params.charity_id
      }),
      type: "POST"
    }).done(function (return_data) {
      console.log(return_data);
      $.alert("charity approved");
      obj_this.props.history.push({
        pathname: '/Dashboard/Charities/All',
        state: obj_this.props.location.previousState ?? ""
      });
      obj_this.context.loading(0);
    }).fail(function () {
      $.alert("Network error");
    });
  }

  delete_charity() {
    if (!window.confirm("Delete this charity")) {
      return;
    }
    this.context.loading(1);
    var obj_this = this;
    $.ajax({
      url: process.env.REACT_APP_API + "/api/deleteCharity",
      data: this.context.bind_session({
        charity_id: this.props.match.params.charity_id
      }),
      type: "POST"
    }).done(function (return_data) {
      console.log(return_data);
      $.alert("Charity deleted");
      obj_this.props.history.replace({
        pathname: '/Dashboard/Charities/All',
        state: obj_this.props.location.previousState ?? ""
      });
      obj_this.context.loading(0);
    }).fail(function () {
      $.alert("Network error");
    });
  }

  render() {
    const AutoplaySlider = withAutoplay(AwesomeSlider);
    return (
      <div className="charity_profile_page_container" ref={this.charity_profile_page_ref}>
        <div className="title_block no_select" style={{ backgroundImage: "url('" + charity_image + "')" }}>
          <div className="title_buttons_container">
            <div className="page_title">{this.state.charity_details.name}</div>
            <hr class="solid"></hr>
            <div className="title_content" dangerouslySetInnerHTML={{
              __html: this.state.charity_details.charity_description,
            }}>
            </div>
            <div className="button_container">
              <div className="notice_button_theme1"
                onClick={() => window.scrollTo({
                  top: this.enquiry_form_ref.current.offsetTop - 40,
                  behavior: "smooth"
                })}>Contact Us
                <div className="button_ghost"></div>
              </div>
            </div>
          </div>
        </div>

        <div className="intro_block">
          <div className="intro_grid">
            <div className="logo_container">
              <div className="logo_image" style={{ backgroundImage: "url('" + process.env.REACT_APP_CDN_URL + "/charities/logo/" + this.state.charity_details.image_src + "')" }}>
              </div>
            </div>
            <div className="intro_details">
              <div className="details_grid">
                <div className="grid_item">
                  <div className="title">Register No:</div>
                  <div className="content">{this.state.charity_details.register_number}</div>
                </div>
                <div className="grid_item">
                  <div className="title">Contact Us:</div>
                  <div className="content">{this.state.charity_details.phone_no1 ? this.state.charity_details.phone_no1 : this.state.charity_details.mobile_no1}</div>
                </div>
                <div className="grid_item">
                  <div className="title">Locality</div>
                  <div className="content">{this.state.charity_details.locality}</div>
                </div>
                <div className="grid_item">
                  <div className="title">City</div>
                  <div className="content">{this.state.charity_details.city}</div>
                </div>
                <div className="grid_item">
                  <div className="title">State</div>
                  <div className="content">{this.state.charity_details.state}</div>
                </div>
                <div className="grid_item">
                  <div className="title">Country</div>
                  <div className="content">{this.state.charity_details.country}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="about_block">
          <div className="about_grid">

            <div className="about_heading">
              <div className="heading">About Us</div>
              {/* <div className="logo_container">
              <div className="logo_image" style={{backgroundImage:"url('"+process.env.REACT_APP_CDN_URL+"/charities/logo/"+this.state.charity_details.image_src+"')"}}>
              </div>
            </div> */}
              <div className="intro_content">
                {this.state.charity_details.introduction}
              </div>
              <div className="about_button">
                <ButtonTheme2
                  label="Send Us An Enquiry"
                  className="create-button"
                  onClick={() => window.scrollTo({
                    top: this.enquiry_form_ref.current.offsetTop - 40,
                    behavior: "smooth"
                  })}
                />
              </div>
            </div>
            <div className="about_content">
              <div className="heading">Vision</div>
              {this.state.charity_details.vision}
              <div className="icon_container">
                <div className="icon" style={{ background: "transparent linear-gradient(90deg, #0f3f6f, #d74f95) repeat scroll 0 0", border: "1px solid #5a2d8f" }}><i className="fas fa-arrow-right"></i></div>
              </div>
            </div>

            <div className="about_second_content">
              <div className="heading">Mission</div>
              {this.state.charity_details.mission}
              <div className="icon_container">
                <div className="icon" style={{ background: "transparent linear-gradient(90deg, #0f3f6f, #d74f95) repeat scroll 0 0", border: "1px solid #5a2d8f" }}><i className="fas fa-arrow-right"></i></div>
              </div>
            </div>
          </div>
        </div>

        <div className="what_we_do_grid">
          <div className="what_we_do_block">
            <div className="section1">
              <div className="heading"> <FontAwesomeIcon icon={faHandHoldingHeart} style={{ 'color': "#cd4e93" }} />&nbsp;&nbsp;What we do</div>
              <div className="content">
                {this.state.charity_details.what_we_do}
              </div>
            </div>
            <div className="section2">
              <div className="heading"><MaterialIcon icon="handshake" color='#cd4e93' size={40} />&nbsp;&nbsp;How we do</div>
              <div className="content">
                {this.state.charity_details.how_we_do}
              </div>
            </div>
            <div className="section3">
              <div className="heading"><FontAwesomeIcon icon={faHandshake} style={{ 'color': "#cd4e93" }} />&nbsp;&nbsp;How you can help?</div>
              <div className="content">
                {this.state.charity_details.how_you_can_help}
              </div>
            </div>
          </div>
        </div>



        {/* <div className="address_contact_details_grid">
          <div className="address_grid_item">
            <div className="item_label">Register No</div>
            <div className="column_sign">:</div>
            <div className="item_value">{this.state.charity_details.register_number}</div>
            <div className="item_label">Address</div>
            <div className="column_sign">:</div>
            <div className="item_value"> {this.state.charity_details.address}{this.state.charity_details.locality?", ":""}{this.state.charity_details.locality}{this.state.charity_details.city?", ":""}{this.state.charity_details.city}{this.state.charity_details.state?", ":""}{this.state.charity_details.state} {this.state.charity_details.country}</div>
            <div className="item_label">Office Hours</div>
            <div className="column_sign">:</div>
            {this.state.charity_details.website?
            <>
            <div className="item_value"> {this.state.charity_details.business_hours}</div>
            <div className="item_label" >Website</div>
            <div className="column_sign">:</div>
            <div className="item_value"> <a target={"_blank"} href={this.state.charity_details.website}>{this.state.charity_details.website}</a></div>
            </>:""}
          </div>
          <div className="contact_details_grid_item">
          {this.state.charity_details.phone_no1 || this.state.charity_details.phone_no2?
            <>
            <div className="item_label">Landline</div>
            <div className="column_sign">:</div>
            <div className="item_value"><a href={"tel:+91"+this.state.charity_details.phone_no1}>{this.state.charity_details.phone_no1}</a>{this.state.charity_details.phone_no2?", ":""}<a href={"tel:+91"+this.state.charity_details.phone_no2}>{this.state.charity_details.phone_no2}</a></div>
            </>:""}
            <div className="item_label">Mobile No.</div>
            <div className="column_sign">:</div>
            <div className="item_value"><a href={"tel:+91"+this.state.charity_details.mobile_no1}>{this.state.charity_details.mobile_no1?"+91 "+this.state.charity_details.mobile_no1:""}</a>{this.state.charity_details.mobile_no2?", +91 ":""}<a href={"tel:+91"+this.state.charity_details.mobile_no2}>{this.state.charity_details.mobile_no2}</a></div>
            <div className="item_label">Whatsapp No.</div>
            <div className="column_sign">:</div>
            <div className="item_value"><a href={"https://api.whatsapp.com/send?phone="+this.state.charity_details.whatsapp_no} target={"_blank"}>{this.state.charity_details.whatsapp_no?"+91 "+this.state.charity_details.whatsapp_no:""}</a></div>
            <div className="item_label">Email</div>
            <div className="column_sign">:</div>
            <div className="item_value"><a href={"mailto:"+this.state.charity_details.email}>{this.state.charity_details.email}</a></div>
          </div>
        </div> */}


        <div className="gallery_block" style={{ display: this.state.charity_details.gallery_images.length ? "block" : "none" }}>
          <div className="global_label">EVENTS</div>
          <div className="single_info_head">
          </div>
          <div className="gallery_heading">What have we done with your help</div>
          <div className="gallery_images_container">
            {this.state.charity_details.gallery_images && this.state.charity_details.gallery_images.map((item, idx) => (
              <div className="single_image_container" key={idx}>
                <div className="single_image"
                  onClick={() => this.zoomImage(item, idx)}
                  style={{ backgroundImage: "url('" + process.env.REACT_APP_CDN_URL + "/charities/gallery_images/" + item.gallery_image_src + "')" }}>
                </div>
              </div>
            ))}
          </div>{/** End of gallery container */}
          <div className="gallery_images_slider">
            <AutoplaySlider cssModule={AwsSliderStyles}
              play={false}
              cancelOnInteraction={false}
              interval={1000}
              onTransitionStart={this.onTransitionStart}
              onTransitionEnd={this.onTransitionEnd}
              transitionDelay={100}
              showTime={false}
              buttons={this.state.charity_details.gallery_images.length > 1 ? true : false}
              mobileTouch={this.state.charity_details.gallery_images.length > 1 ? true : false}
              bullets={false}
              organicArrows={true}
            // mobileTouch={true}
            >
              {this.state.charity_details.gallery_images && this.state.charity_details.gallery_images.map((item, idx) => (
                <div className="slider_image" key={idx} data-src={process.env.REACT_APP_CDN_URL + "/charities/gallery_images/" + item.gallery_image_src}></div>
              ))}
            </AutoplaySlider>
          </div>
        </div>

        <div className="bank_details_block">
          <div className="global_label">Small Efforts Make Big Changes</div>
          <div className="global_heading">We have the power today to change tomorrow, and we rise by lifting others.</div>
          <div className="bank_details_grid">
            {this.state.charity_details.bank_details && this.state.charity_details.bank_details.map((item, idx) => (
              <div className="single_bank_details" key={idx}>
                {/* <div className="icon"><i className="fas fa-hand-holding-heart" style={{ color: "#f5672a" }}  ></i></div> */}

                <div className="icon_container">
                  <div className="icon" style={{ background: "transparent linear-gradient(90deg, #0f3f6f, #d74f95) repeat scroll 0 0", border: "1px solid #5a2d8f" }}><i className="fas fa-hand-holding-heart"></i></div>
                </div>{/* <div className="account_type">Bank {idx+1}</div> */}
                <div className="account_type">Make Donations, Make a Difference</div>
                <div className="details_container">{item.account_type} <br></br>
                  {item.account_details}</div>
              </div>
            ))}
          </div>
        </div>

        <div className="comments_block">
          <div className="global_label">Testimonials</div>
          <div className="global_heading">What our clients say</div>
          <div style={{ textAlign: "center", marginTop: "20px", marginBottom: "10px" }}>
            <button className="new_comment_button"
              onClick={this.show_comment_form}
            >
              <div className="button_ghost"></div>
              <i className="fas fa-pencil-alt"></i>
              <span className="button_label"> Leave a comment </span>
            </button>
          </div>
          <Carousel
            additionalTransfrom={0}
            arrows
            centerMode={false}
            className="msg_container"
            containerClass="slider_container"
            dotListClass=""
            draggable
            focusOnSelect={false}
            infinite={false}
            itemClass=""
            keyBoardControl
            minimumTouchDrag={80}
            renderButtonGroupOutside={false}
            renderDotsOutside={false}
            responsive={{
              desktop: {
                breakpoint: {
                  max: 2400,
                  min: 1080
                },
                items: 2,
                partialVisibilityGutter: 100
              },
              mobile: {
                breakpoint: {
                  max: 780,
                  min: 0
                },
                items: 1,
                partialVisibilityGutter: 30
              },
              tablet: {
                breakpoint: {
                  max: 1024,
                  min: 780
                },
                items: 2,
                partialVisibilityGutter: 30
              }
            }}
            showDots={true}
            sliderClass=""
            slidesToSlide={1}
            swipeable>
            {this.state.charity_details.comments && this.state.charity_details.comments.map((item, idx) => (
              <div className="single_msg" key={idx}>
                <div className="comment_details">
                  <div className="commented_user">{item.fullname}</div>
                  {/* <div className="user_location">{item.user_location}</div> */}
                  <div className="commented_date">{new Date(item.published_date).toLocaleString("en-IN", { weekday: "long" })}, {new Date(item.published_date).getDate()} {this.month_names[new Date(item.published_date).getMonth()]} {new Date(item.published_date).getFullYear()}</div>
                </div>
                <hr style={{ width: "50%", marginLeft: "auto", marginRight: "auto", marginTop: "20px" }}></hr>
                {/* <div className="comment_header"> */}
                {/* {item.user_image_src ?
                    <div className="user_image" style={{ backgroundImage: "url('" + process.env.REACT_APP_CDN_URL + "/user_profile_images/" + item.user_image_src + "')" }}></div>
                    :
                    <FaUserCircle size={40} color="grey" />
                  } */}
                {/* <div className="rating"> */}
                {/* <BsStarFill className="rating_star" />
                  <BsStarFill className="rating_star" />
                  <BsStarFill className="rating_star" />
                  <BsStarFill className="rating_star" />
                  <BsStarHalf className="rating_star" /> */}
                {/* </div> */}
                <div className="comment_body">{item.comment}</div>
                <FaUserCircle
                  size={80}
                  color="#693780"
                  className="comments_user_icon"
                />

                {/* </div> */}
                <div className="report_button" onClick={() => this.report_comment(item.id)}>
                  <GoReport />
                  <div className="report_label">Report </div>
                </div>
              </div>
            ))}
          </Carousel>
        </div>

        {/* <div className="charity_footer" style={{display:this.state.charity_details.website || this.state.charity_details.email?"block":"none"}}>
          <div className="website"><a target={"_blank"} href={this.state.charity_details.website}>{this.state.charity_details.website}</a></div>
          <div className="website"><a href={"mailto:"+this.state.charity_details.email}>{this.state.charity_details.email}</a></div>
        </div> */}
        <div className="enquiry_form_block" ref={this.enquiry_form_ref}>
          <form className="enquiry_form_grid" onSubmit={this.enquiry_form_submit}>
            <div className="contact_details">
              <div className="contact_us_label">Contact Us</div>
              <div className="icon_details_grid">
                <div className="icon_container">
                  <FaMapMarkerAlt className="icon" />
                </div>
                <div className="details_container">
                  <div className="heading">Our Office</div>
                  <div className="content">{this.state.charity_details.address}</div>
                </div>
                <div className="icon_container">
                  <FaBusinessTime className="icon" />
                </div>
                <div className="details_container">
                  <div className="heading">Business Hours</div>
                  <div className="content">{this.state.charity_details.business_hours}</div>
                </div>
                <div className="icon_container">
                  <FaPhoneAlt className="icon" />
                </div>
                <div className="details_container">
                  <div className="heading">Call Us</div>
                  <div className="content"><a href={"tel:" + this.state.charity_details.mobile_no1}>{this.state.charity_details.mobile_no1}{this.state.charity_details.mobile_no2 ? ", " + this.state.charity_details.mobile_no2 : ""}</a></div>
                </div>
                <div className="icon_container">
                  <SiWhatsapp className="icon" />
                </div>
                <div className="details_container">
                  <div className="heading">WhatsApp</div>
                  <div className="content"><a href={"https://api.whatsapp.com/send?phone=" + this.state.charity_details.whatsapp_no} target={"_blank"}>{this.state.charity_details.whatsapp_no}</a></div>
                </div>
                <div className="icon_container">
                  <SiGmail className="icon" />
                </div>
                <div className="details_container">
                  <div className="heading">Mail Us</div>
                  <div className="content"><a href={"mailto:" + this.state.charity_details.email}>{this.state.charity_details.email}</a></div>
                </div>
                <div className="icon_container" style={{ display: this.state.charity_details.website ? "block" : "none" }}>
                  <FaGlobe className="icon" />
                </div>
                <div className="details_container" style={{ display: this.state.charity_details.website ? "block" : "none" }}>
                  <div className="heading">Website</div>
                  <div className="content"><a href={this.state.charity_details.website} target={"_blank"}>{this.state.charity_details.website}</a></div>
                </div>
              </div>
              <div className="social_links_container">
                <a style={{ display: this.state.charity_details.facebook ? "inline-grid" : "none" }} href={this.state.charity_details.facebook} target={"_blank"}><i className="fab fa-facebook-f contact_icon"></i></a>
                <a style={{ display: this.state.charity_details.instagram ? "inline-grid" : "none" }} href={this.state.charity_details.instagram} target={"_blank"}><i className="fab fa-instagram contact_icon"></i></a>
                <a style={{ display: this.state.charity_details.linkedin ? "inline-grid" : "none" }} href={this.state.charity_details.linkedin} target={"_blank"}><i className="fab fa-linkedin contact_icon"></i></a>
                <a style={{ display: this.state.charity_details.twitter ? "inline-grid" : "none" }} href={this.state.charity_details.twitter} target={"_blank"}>
                  <img src={x_white_logo} className="contact_icon" style={{ height: "18px", display: "inline-grid" }} />
                </a>
              </div>
            </div>
            <div className="form_container">
              <div className="global_label send_us_label">Send Us An Enquiry</div>
              <InputTheme4
                label="Name"
                attrs={{
                  name: "name",
                  type: "text",
                  value: this.state.name,
                  onChange: this.form_change,
                }}
                ref={this.name_ref}
              />
              {/* <InputTheme4
              label="Phone number"
              attrs={{
                name: "phone_no",
                type: "number",
                value: this.state.phone_no,
                onChange: this.form_change,
                pattern : "^[0]?[1-9][0-9]{9}$",
                required : true
              }}
            /> */}
              <div className="mobile_no_input_container">
                <PhoneInput
                  style={{
                    borderBottom: this.state.phone_no
                      ? "2px solid #4f0662"
                      : "",
                    color: this.state.phone_no ? "#111" : "",
                  }}
                  placeholder="Phone number"
                  international
                  value={this.state.phone_no}
                  onChange={this.phone_no_change}
                  defaultCountry={this.state.phone_number_code}
                  onCountryChange={(country) =>
                    this.phone_number_code_change(country)
                  }
                  ref={this.phone_no_ref}
                />
              </div>

              <InputTheme4
                label="Email"
                attrs={{
                  name: "email",
                  type: "email",
                  value: this.state.email,
                  onChange: this.form_change,
                  autoComplete: "off",
                }}
                input_ref={this.email_ref}
              />
              <textarea
                name="message"
                value={this.state.message}
                placeholder="Message (max 150 words)"
                onChange={this.form_change}
                rows={5}
                input_ref={this.message_ref}
              />
              <ReCAPTCHA
                sitekey={process.env.REACT_APP_SITE_KEY}
                ref={this.captchaRef}
              />
              <button className="submit_button enquiry_submit_button"
                type="submit"
                // onClick={this.show_comment_form}
              >
                <div className="button_ghost"></div>
                <span className="button_label">  Send Enquiry </span>
              </button>

              {/* <button className="submit_button" type="submit">
                Send Enquiry</button> */}
            </div>
          </form>
        </div>
        <div className="buttons_container">
          <div className="share_print_comment_buttons_container">
            <div className="back_button custom_button"
              onClick={() => this.props.history.goBack()}
            >
              <div className="icon_container">
                <MdOutlineKeyboardBackspace className="icon" />
              </div>
              <div className="button_label">Back</div>
              {/* <ImArrowRight2 size={18}  className="arrow_icon"/> */}
            </div>
            {/* <div className="report_page_button custom_button"
            onClick={this.show_report_page_form}
          >
            <div className="icon_container">
              <GoReport className="icon"/>
            </div>
            <div className="button_label">Report this page</div>
            <ImArrowRight2 size={18}  className="arrow_icon"/>
          </div> */}
            {/* <div className="comment_button custom_button"
            onClick={this.show_comment_form}
          >
            <div className="icon_container">
              <FaRegComment className="icon"/>
            </div>
            <div className="button_label">Comment</div>
            <ImArrowRight2 size={18}  className="arrow_icon"/>
          </div> */}
            <div className="share_button custom_button">
              <div className="icon_container">
                <MdOutlineShare className="icon" />
              </div>
              <div className="button_label">Share</div>
              {/* <ImArrowRight2 size={18}  className="arrow_icon"/> */}
              <div className="share_buttons_container">
                <a href={"http://www.facebook.com/share.php?u=" + encodeURIComponent(process.env.REACT_APP_SHARE_URL + "/Charity/" + this.state.charity_details.id + "/" + this.state.charity_details.name.replace(/\s+/g, '-') + "/" + this.state.charity_details.serial_no)} target={"_blank"}>
                  <i className="fab fa-facebook-f share_icon" style={{ color: "#0f92f3" }}></i>
                  <span>Facebook</span>
                </a>
                <a href={"https://api.whatsapp.com/send?text=" + this.state.charity_details.name + " " + encodeURIComponent(process.env.REACT_APP_SHARE_URL + "/Charity/" + this.state.charity_details.id + "/" + this.state.charity_details.name.replace(/\s+/g, '-') + "/" + this.state.charity_details.serial_no)} data-action="share/whatsapp/share" target={"_blank"}>
                  <i className="fab fa-whatsapp share_icon" style={{ color: "#40bf50" }}></i>
                  <span>WhatsApp</span>
                </a>
                {/* <i className="fab fa-instagram share_icon"></i> */}
                <a href={"mailto:?subject=" + this.state.charity_details.name + "&body=" + encodeURIComponent(process.env.REACT_APP_SHARE_URL + "/Charity/" + this.state.charity_details.id + "/" + this.state.charity_details.name.replace(/\s+/g, '-') + "/" + this.state.charity_details.serial_no)} target={"_blank"}>
                  <i className="far fa-envelope-open share_icon" style={{ color: "#d10a0a" }}></i>
                  <span>Mail</span>
                </a>
                <a href={"https://x.com/intent/tweet?url=" + encodeURIComponent(process.env.REACT_APP_SHARE_URL + "/Charity/" + this.state.charity_details.id + "/" + this.state.charity_details.name.replace(/\s+/g, '-') + "/" + this.state.charity_details.serial_no)} target={"_blank"}>
                  <img src={x_logo} className="share_icon" style={{ paddingLeft: "5px", height: "20px" }} />
                  <span>X</span>
                </a>
                <a onClick={() => {
                  var copyURL = process.env.REACT_APP_SHARE_URL + "/Charity/" + this.state.charity_details.id + "/" + this.state.charity_details.name.replace(/\s+/g, '-') + "/" + this.state.charity_details.serial_no;
                  navigator.clipboard.writeText(copyURL);
                  $.alert("URL copied to clipboard");
                  setTimeout(() => {
                    $(".custom_alert_container .custom_alert_box .custon_alert_ok_button").trigger("click");
                  }, 2000);
                }}>
                  <i className="fa fa-clone share_icon" style={{ color: "grey" }}></i>
                  <span>Copy URL</span>
                </a>
              </div>
            </div>

            <div className="print_button custom_button"
              onClick={() => { window.print(); }}
            >
              <div className="icon_container">
                <MdOutlinePrint className="icon" />
              </div>
              <div className="button_label">Print</div>
            </div>
          </div>
        </div>
        {this.state.charity_details.google_location && this.state.charity_details.google_location.includes("<iframe") ?
          <div className="map_block">

          </div> : ""}
        <div className="approve_reject_buttons_container" style={{ display: this.state.manage_charity ? "block" : "none" }}>
          <ButtonTheme1
            label="<< Back"
            className="previous_page_button"
            onClick={() => this.props.history.goBack()}
          ></ButtonTheme1>
          <ButtonTheme1
            label="Delete"
            className="delete_button"
            onClick={this.delete_charity}
            style={{ display: this.props.history.location.action == "delete" && this.state.charity_details.status != "DELETED" ? "inline-block" : "none" }}
          ></ButtonTheme1>
        </div>
        {/* <div className="zoomed_image_ghost" onClick={() => this.setState({ zoomed_item: "" })} style={{ display: this.state.zoomed_item.gallery_image_src ? "block" : "none" }}>
          <div className="zoomed_image_container" onClick={(e) => e.stopPropagation()}>
            <div className="image_caption_container">
              <img src={process.env.REACT_APP_CDN_URL + "/charities/gallery_images/" + this.state.zoomed_item.gallery_image_src} />
              <div style={{ display: this.state.zoomed_item.gallery_image_caption ? "block" : "none" }} className="image_caption">{this.state.zoomed_item.gallery_image_caption}</div>
              <div className="close_zoomed_image_button" onClick={this.minimizeImage}>
                <i className="fas fa-times"></i>
              </div>
            </div>
            <MdOutlineArrowBackIos
              className="image_previous_button"
              size={50}
              color="#333"
              onClick={this.previousImage}
              style={{ display: this.state.image_index == 0 ? "none" : "block" }}
            />
            <MdOutlineArrowForwardIos
              className="image_next_button"
              size={50}
              color="#333"
              onClick={this.nextImage}
              style={{ display: this.state.charity_details.gallery_images.length == this.state.image_index + 1 ? "none" : "block" }}
            />
          </div>
        </div> */}
        <div className="add_message_form_ghost">
          <form className="add_message_form"
            onClick={(e) => e.stopPropagation()}
            onSubmit={this.comment_form_submit}
          >
            <div className="add_message_title">Add a Comment</div>
            <div className="leave_message_label">Write about your experience with us</div>
            <textarea
              name="comment"
              value={this.state.comment}
              rows={5}
              onChange={this.form_change}
              // maxLength={200}
              required
            />
            <div className="maximum_character_label">Max 200 characters</div>
            <ButtonTheme2 label="Send" type="submit" className="save_message_button" />
            <div className="close_message_form"
              onClick={() => {
                $(".add_message_form_ghost").css("display", "none");
                this.setState({
                  comment: ""
                })
              }}
            >
              <div>x</div>
            </div>
          </form>
        </div>
        <div className="zoomed_image_ghost" onClick={() => this.setState({ zoomed_item: "" })} style={{ display: this.state.zoomed_item.gallery_image_src ? "block" : "none" }}>
          <div className="zoomed_image_container" onClick={(e) => e.stopPropagation()}>
            <div className="image_caption_container">
              <img src={process.env.REACT_APP_CDN_URL + "/charities/gallery_images/" + this.state.zoomed_item.gallery_image_src} />
              <div style={{ display: this.state.zoomed_item.gallery_image_caption ? "block" : "none" }} className="image_caption">{this.state.zoomed_item.gallery_image_caption}</div>
              <div className="close_zoomed_image_button" onClick={this.minimizeImage}>
                <VscChromeClose />
              </div>
            </div>
            <div className="arrow_container">
              <MdOutlineArrowBackIos
                className="image_previous_button"
                size={45}
                color="#333"
                onClick={this.previousImage}
                style={{ pointerEvents: this.state.image_index == 0 ? "none" : "auto", opacity: this.state.image_index == 0 ? 0.2 : 1 }}
              />
              <MdOutlineArrowForwardIos
                className="image_next_button"
                size={45}
                color="#333"
                onClick={this.nextImage}
                style={{ pointerEvents: this.state.charity_details.gallery_images.length == this.state.image_index + 1 ? "none" : "auto", opacity: this.state.charity_details.gallery_images.length == this.state.image_index + 1 ? 0.2 : 1 }}
              />
            </div>
          </div>

        </div>
      </div>
    )
  }
}

export default CharityProfile;
CharityProfile.contextType = MyContext;